<template>
  <a-modal
    class="system-edit-modal"
    title="Редактировать систему"
    :visible="modalVisible"
    :closable="true"
    :confirm-loading="!(!loading && !confirmLoading)"
    @ok="update"
    @cancel="resetForm"
  >
    <a-form :model="system">
      <div class="col-md-12">
        <a-form-item label="Порядковый номер">
          <a-input
            placeholder=""
            v-model:value="system.order"
          />
        </a-form-item>
        <a-form-item label="Название">
          <a-input
            ref="systemName"
            placeholder="Введите"
            v-model:value="system.name"
          />
        </a-form-item>
        <a-form-item label="URL логотипа">
          <a-input
            placeholder=""
            v-model:value="system.icon"
          />
        </a-form-item>
        <a-form-item label="Описание">
          <a-input
            placeholder=""
            v-model:value="system.hint"
          />
        </a-form-item>
        <a-form-item label="URL картинки (вместо названия)">
          <a-input
            placeholder=""
            v-model:value="system.label"
          />
        </a-form-item>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import { PlusOutlined, LoadingOutlined, CloseCircleOutlined } from '@ant-design/icons-vue';
import {ref, reactive, toRaw, onMounted, nextTick} from 'vue';
import { getHeaders } from "@/helpers/header"
import { getBase64 } from "@/helpers/base64";
import { message } from "ant-design-vue";
import apiClient from "@/services/axios";

const props = defineProps({
  systemId: {
    type: Number,
    default: 0,
  },
})

const emit = defineEmits(['hide-modal', 'reload']);

let imageUrl = ref(''),
  loading = ref(true),
  confirmLoading = ref(false),
  modalVisible = ref(true),
  systemName = ref("systemName"),
  system = reactive({
    name: '',
    hint: '',
    icon: null,
    order: null,
    label: null,
    disabled: false,
  });

const
  resetForm = () => {
    Object.assign(system, {
      name: '',
      hint: '',
      icon: null,
      order: null,
      label: null,
      disabled: 0,
    });
    modalVisible.value = false;
    emit('hide-modal')
  },
  update = () => {
    confirmLoading.value = true;

    let model = toRaw(system)

    return apiClient
      .put(`/payment-system/update?id=${props.systemId}`, model)
      .then(response => {
        if (response) {
          resetForm()
        }
      }).catch(() => {
        // console.log(err)
      }).finally(() => {
        confirmLoading.value = false;
        modalVisible.value = false;
        emit('reload')
        emit('hide-modal')
      })
  };

apiClient.get(`/payment-system/view?id=${props.systemId}`).then(response => {
  if (response) {
    Object.assign(system, {
      name: response.data.data.name,
      hint: response.data.data.hint,
      icon: response.data.data.icon,
      order: response.data.data.order,
      label: response.data.data.label,
      disabled: response.data.data.disabled,
    })
  }
}).catch(() => {
  // console.log(err)
}).finally(() => {
  loading.value = false
})

onMounted(() => nextTick(() => systemName.value?.focus()))
</script>

<style scoped>
@import "./edit.css";
</style>
