<template>
  <a-button class="editable-add-btn" @click="openModal" style="margin-bottom: 16px">Добавить пл. систему</a-button>
  <a-modal
    class="system-create-modal"
    title="Добавить пл. систему"
    :visible="modalVisible"
    :closable="true"
    :confirm-loading="!(!loading && !confirmLoading)"
    @ok="create"
    @cancel="resetForm"
  >
    <a-form :model="system">
      <div class="col-md-12">
        <a-form-item label="Название">
          <a-input
            ref="systemName"
            placeholder="Введите название"
            v-model:value="system.name"
          />
        </a-form-item>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import {ref, reactive, toRaw, nextTick} from 'vue';
import apiClient from "@/services/axios";

const emit = defineEmits(['reload']);

let imageUrl = ref(''),
  loading = ref(false),
  confirmLoading = ref(false),
  modalVisible = ref(false),
  systemName = ref("systemName"),
  system = reactive({
    name: '',
    disabled: 0,
  });

const
  openModal = () => {
    modalVisible.value = true;
    nextTick(() => systemName.value?.focus());
  },
  resetForm = () => {
    Object.assign(system, {
      name: '',
      disabled: false,
    });
    modalVisible.value = false;
  },
  create = () => {
    confirmLoading.value = true;

    let model = toRaw(system);

    return apiClient
      .post('/payment-system/create', model)
      .then(function (response) {
        if (response) {
          resetForm()
          modalVisible.value = false;
          confirmLoading.value = false;
          emit('reload')
        }
      })
      .catch(() => {
        modalVisible.value = false;
        confirmLoading.value = false;
        emit('reload')
        // console.log(err)
      })
      .finally(() => {
        confirmLoading.value = false;
        emit('reload')
      })
  }
</script>

<style scoped>
@import "./create.css";
</style>
